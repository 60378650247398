// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-card-game-index-js": () => import("./../src/pages/cardGame/index.js" /* webpackChunkName: "component---src-pages-card-game-index-js" */),
  "component---src-pages-credit-card-refund-index-ts": () => import("./../src/pages/creditCardRefund/index.ts" /* webpackChunkName: "component---src-pages-credit-card-refund-index-ts" */),
  "component---src-pages-email-sign-up-index-ts": () => import("./../src/pages/emailSignUp/index.ts" /* webpackChunkName: "component---src-pages-email-sign-up-index-ts" */),
  "component---src-pages-email-sign-up-result-index-ts": () => import("./../src/pages/emailSignUp/result/index.ts" /* webpackChunkName: "component---src-pages-email-sign-up-result-index-ts" */),
  "component---src-pages-identity-index-ts": () => import("./../src/pages/identity/index.ts" /* webpackChunkName: "component---src-pages-identity-index-ts" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-marketing-terms-mt-20200824-tsx": () => import("./../src/pages/marketingTerms/MT20200824.tsx" /* webpackChunkName: "component---src-pages-marketing-terms-mt-20200824-tsx" */),
  "component---src-pages-privacy-pp-20200727-tsx": () => import("./../src/pages/privacy/PP20200727.tsx" /* webpackChunkName: "component---src-pages-privacy-pp-20200727-tsx" */),
  "component---src-pages-receipt-index-tsx": () => import("./../src/pages/receipt/index.tsx" /* webpackChunkName: "component---src-pages-receipt-index-tsx" */),
  "component---src-pages-terms-ts-20200727-tsx": () => import("./../src/pages/terms/TS20200727.tsx" /* webpackChunkName: "component---src-pages-terms-ts-20200727-tsx" */),
  "component---src-pages-wallet-receipt-index-tsx": () => import("./../src/pages/walletReceipt/index.tsx" /* webpackChunkName: "component---src-pages-wallet-receipt-index-tsx" */)
}

